<template>
  <div style="min-height: 100vh; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center">
    <div style="text-align: center">
      <p class="text-h3">AR - Maps</p>
      <v-card class="mt-10" style="min-width: 350px">
        <v-card-text>
          <v-text-field v-model="name" @keydown.enter="submit" prepend-icon="mdi-account" type="text" label="Name" :disabled="fetching" />
          <v-text-field v-model="password" @keydown.enter="submit" :prepend-icon="registerMode ? 'mdi-account-key' : 'mdi-key'" type="password" label="Passwort" :disabled="fetching" />
          <v-text-field v-if="registerMode" v-model="passwordRepeat" @keydown.enter="submit" prepend-icon="mdi-repeat" type="password" label="Passwort wiederholen" :disabled="fetching" />
          <v-text-field v-if="registerMode" v-model="code" @keydown.enter="submit" prepend-icon="mdi-shield-key" type="text" label="Zugangscode" :disabled="fetching" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="registerMode = !registerMode" text>
            {{ registerMode ? 'Einloggen' : 'Registrieren' }}
          </v-btn>
          <v-spacer />
          <v-btn @click="submit" color="primary" :disabled="fetching">
            {{ registerMode ? 'Registrieren' : 'Login' }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <div id="privacy-container" class="text-body-1">
      <a :href="privacyUrl" target="_blank" class="text-decoration-underline">Datenschutz</a>
    </div>

    <GenericDialog v-model="privacyDialog" title="AR-Maps">
      <template v-slot:content>
        Diese Seite funktioniert nur auf mobilen Endgeräten
        <br>
        mit folgenden Systemvoraussetzungen:
        <br><br>
        <ul>
          <li>Kamera</li>
          <li>GPS</li>
          <li>Gyroskop</li>
          <li>Kompass</li>
        </ul>
        <br>
        Mit dem Zugang akzeptieren Sie die <a :href="privacyUrl" target="_blank" rel="noopener">datenrechtlichen Bestimmungen</a>
        <br>
        sowie die Verwendung von Cookies für diese App.
      </template>

      <template v-slot:actions>
        <v-btn class="primary" @click="privacyDialog = false">
          Akzeptieren
        </v-btn>
      </template>
    </GenericDialog>

  </div>
</template>

<script>
import {getAccountInfo, login, register} from '@/api'
import {showSnackbar} from "@/utils";
import GenericDialog from "@/components/dialog/GenericDialog";

export default {
  name: 'Login',
  components: {GenericDialog},
  props: {
    firstLogin: {
      type: Boolean
    }
  },
  data: () => ({
    fetching: false,
    name: null,
    password: null,
    passwordRepeat: null,
    code: null,
    registerMode: false,
    privacyDialog: false,
    privacyUrl: 'https://stephaneum.de/impressum'
  }),
  methods: {
    submit: async function() {
      this.fetching = true;
      try {
        if (this.registerMode) {

          if (!this.name || !this.password || !this.passwordRepeat || !this.code) {
            showSnackbar('Alle Felder ausfüllen');
            return;
          }

          if (this.password !== this.passwordRepeat) {
            showSnackbar('Passwörter stimmen nicht überein');
            return;
          }

          await register({ name: this.name, password: this.password, code: this.code });
        } else {

          if (!this.name || !this.password) {
            showSnackbar('Alle Felder ausfüllen');
            return;
          }

          await login({ name: this.name, password: this.password });
        }

        await this.$emit('update-login');

        const user = getAccountInfo();
        if (user.admin)
          await this.$router.push('/users');
        else
          window.location = 'app';
      } catch(status) {
        switch (status) {
          case 403:
            showSnackbar('Login fehlgeschlagen');
            break;
          case 404:
            showSnackbar('Falscher Zugangscode');
            break;
          case 409:
          case 412:
            showSnackbar('Name besetzt.');
            break;
          default:
            showSnackbar('Ein Fehler ist aufgetreten');
            break;
        }

      } finally {
        this.fetching = false;
      }
    }
  },
  mounted: function() {
    this.privacyDialog = this.firstLogin;
  }
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
  #privacy-container {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }
}

@media screen and (min-width: 601px) {
  #privacy-container {
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: right;
  }
}
</style>