<template>
  <nav>
    <v-app-bar v-if="loggedIn" class="hidden-lg-and-up primary" dark>
      <v-app-bar-nav-icon @click="drawerVisible = true"/>
      <v-toolbar-title>
        AR - Maps
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-if="loggedIn" v-model="drawerVisible" class="blue darken-4" dark app>

      <v-container class="white--text mt-4" style="text-align: center">
        <p class="text-h5 mb-0">AR - Maps</p>
        <p class="text-h7">Administration</p>
      </v-container>

      <v-list shaped dense>

        <v-list-item v-for="p in pages" :key="p.route" link :to="p.route">
          <v-list-item-icon>
            <v-icon>{{ p.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ p.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link class="mt-8 white" @click="logout" light :disabled="loggingOut">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ loggingOut ? 'Wird abgemeldet...' : 'Abmelden' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>
  </nav>
</template>

<script>

import {logout} from "@/api";

export default {
  name: 'Navigation',
  props: ['loggedIn'],
  data: () => ({
    loggingOut: false,
    drawerVisible: false,
    pages: [
      {
        route: '/users',
        label: 'Nutzer',
        icon: 'mdi-account-multiple'
      },
      {
        route: '/codes',
        label: 'Zugangscodes',
        icon: 'mdi-key'
      }
    ]
  }),
  methods: {
    logout: async function () {
      this.loggingOut = true;
      await logout();
      this.loggingOut = false;
      await this.$emit('update-login');
      await this.$router.push('/');
    }
  },
  watch: {
    loggedIn: {
      immediate: true,
      handler: function (val) {
        if (this.drawerVisible) {
          if (!val) {
            this.drawerVisible = false; // loggedIn -> logout
          }
        } else {
          this.drawerVisible = val ? null : false; // initial
        }
      }
    }
  }
}
</script>