<template>
  <MainContainer title="Zugangscodes" icon="mdi-key">
    <template slot="meta">
      <p class="text-h6">Aktionen</p>

      <p>Zugangscodes: {{ codes.length }}</p>

      <v-btn @click="createCode()" color="primary" block :disabled="loading">
        <v-icon left>mdi-plus</v-icon>
        Neuer Code
      </v-btn>
    </template>

    <LoadingIndicator v-if="fetching && codes.length === 0" />
    <Notice v-if="!fetching && codes.length === 0" title="Keine Zugangscodes vorhanden." />

    <v-card v-for="c in codes" :key="c.id" class="mb-4">
      <v-card-text>
        <div style="display: flex; align-items: center">
          <span style="flex: 1">{{ c.code }}</span>
          <v-btn @click="deleteCode(c)" :disabled="loading" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </MainContainer>
</template>

<script>
import MainContainer from "@/components/MainContainer";
import {showSnackbar} from "@/utils";
import {createCode, deleteCode, getCodes} from "@/api";
import LoadingIndicator from "@/components/LoadingIndicator";
import Notice from "@/components/Notice";

export default {
  name: 'Codes',
  components: {Notice, LoadingIndicator, MainContainer},
  data: () => ({
    fetching: false,
    loading: false,
    codes: []
  }),
  methods: {
    fetchData: async function() {
      this.fetching = true;
      try {
        this.codes = await getCodes();
      } catch (e) {
        showSnackbar('Ein Fehler ist aufgetreten');
      } finally {
        this.fetching = false;
      }
    },
    createCode: async function() {
      try {
        this.loading = true;
        await createCode();
        await this.fetchData();
      } catch (e) {
        showSnackbar('Ein Fehler ist aufgetreten');
      } finally {
        this.loading = false;
      }
    },
    deleteCode: async function(code) {
      try {
        this.loading = true;
        await deleteCode({ codeId: code.id });
        await this.fetchData();
      } catch (e) {
        showSnackbar('Ein Fehler ist aufgetreten');
      } finally {
        this.loading = false;
      }
    }
  },
  mounted: async function() {
    await this.fetchData();
  }
}
</script>