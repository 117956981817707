import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Codes from "@/views/Codes";
import Users from "@/views/Users";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/codes',
    name: 'Codes',
    component: Codes
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
