const API_URL = "/api";

let me = null;
let handle401 = null;

async function makeRequest({ method, route, body, params, no401Callback, noException }) {
    const url = new URL(API_URL+'/'+route, document.location);

    if (params) {
        // add query params
        for (let propName in params) {
            if (params[propName] === null || params[propName] === undefined) {
                delete params[propName];
            }
        }

        url.search = new URLSearchParams(params).toString();
    }

    const response = await fetch(url, {
        method,
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
    });

    // handle 401
    if(!no401Callback && response.status === 401 && handle401) {
        me = null;
        await handle401();
        return {};
    }

    // handle errors
    if(!noException && response.status >= 400) {
        throw response.status;
    }

    // return the result
    return JSON.parse((await response.text()) || "{}");
}

export function initAPI({ handle401: h401 }) {
    handle401 = h401;
    console.log("API initialized.");
}

export async function initAccountInfo() {
    try {
        me = await makeRequest({
            route: "account",
            method: "GET",
            no401Callback: true
        });
        console.log("Already logged in.");
    } catch (e) {
        console.log("Not logged in.");
    }
}

export function getAccountInfo() {
    return me;
}

export async function login({ name, password }) {
    const response = await makeRequest({
        route: "login",
        method: "POST",
        body: {name, password},
    });

    me = response;
    return response;
}

export async function register({ name, password, code }) {
    return await makeRequest({
        route: "register",
        method: "POST",
        body: {name, password, code},
    });
}

export async function logout() {
    me = null;
    await makeRequest({
        route: 'logout',
        method: 'POST',
        noException: true
    });
}

export async function getCodes() {
    return await makeRequest({
        route: 'admin/codes',
        method: 'GET'
    });
}

export async function createCode() {
    return await makeRequest({
        route: 'admin/codes/create',
        method: 'POST'
    });
}

export async function deleteCode({ codeId }) {
    return await makeRequest({
        route: 'admin/codes',
        method: 'DELETE',
        params: {codeId}
    });
}

export async function getUsers() {
    return await makeRequest({
        route: 'admin/users',
        method: 'GET'
    });
}

export async function createUser({ name, password }) {
    return await makeRequest({
        route: 'admin/users/create',
        method: 'POST',
        body: {name, password}
    });
}

export async function updateUserName({ user, name }) {
    return await makeRequest({
        route: 'admin/users/update-name',
        method: 'PUT',
        body: {user, name}
    });
}

export async function updateUserPassword({ user, password }) {
    return await makeRequest({
        route: 'admin/users/update-password',
        method: 'PUT',
        body: {user, password}
    });
}

export async function deleteUser({ userId }) {
    return await makeRequest({
        route: 'admin/users',
        method: 'DELETE',
        params: {userId}
    });
}