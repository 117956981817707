
let snackbarFunc;

export function initSnackbar(func) {
    snackbarFunc = func;
}

export function showSnackbar(text) {
    snackbarFunc(text);
}

export function sleep(millis) {
    return new Promise(resolve => setTimeout(resolve, millis));
}